import { useEffect, useState } from 'react'
import { getBaseUri, getMaxSupply, getTotalSupply, mint } from '../util/blockchain'
import { useMetaMask } from './useMetaMask'

interface ContractInfo {
  baseUri: string
  maxSupply: number
  totalSupply: number
}

interface Aggregations {
  [key: string]: {
    values: {
      [key: string]: {
        count: number
        percentage: number
      }
    }
    totalCount: number
  }
}

export function useContractScan(contractAddress: string) {
  const { account, web3 } = useMetaMask()
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState(`Scaning ${contractAddress}`)
  const [rarest, setRarest] = useState<any>()
  const [contractInfo, setContractInfo] = useState<ContractInfo>()
  const [aggregated, setAggregated] = useState<Aggregations>()

  useEffect(() => {
    async function fetchContractInfo() {
      setLoading(true)
      setMessage(`Fetching contract info from ${contractAddress}...`)
      try {
        const [baseUri, maxSupply, totalSupply] = await Promise.all([
          getBaseUri(contractAddress),
          getMaxSupply(contractAddress),
          getTotalSupply(contractAddress),
        ])
        setContractInfo({ baseUri, maxSupply: Number(maxSupply), totalSupply: Number(totalSupply) })
      } catch {
        setLoading(false)
        setMessage('😓 Not able to fetch information, please try again later')
        return
      }
    }
    if (contractAddress) {
      fetchContractInfo()
    }
  }, [contractAddress])

  useEffect(() => {
    if (!rarest?.edition || !account) {
      return
    }
    const interval = setInterval(async () => {
      const totalSupply = await getTotalSupply(contractAddress)
      setMessage(`Waiting for next mint to be edition #${rarest?.edition}, last token minted is #${totalSupply}`)
      if (Number(totalSupply) + 1 === Number(rarest?.edition)) {
        setMessage(`It's time! ⚡️`)
        setLoading(false)
        clearInterval(interval);
        mint(account as any, contractAddress, web3, (receipt: any) => {
          if (receipt.status) {
            setMessage(`🎉 Token minted!`)
          } else {
            setMessage(`😓 Token minting failed!`)
          }
        })
      } else if (Number(totalSupply) >= Number(rarest?.edition)) {
        setMessage(`😞 Token #${rarest?.edition} was already minted, refresh to rescan`)
        setLoading(false)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [rarest])

  useEffect(() => {
    async function fetchAllMetadata() {
      if (contractInfo?.baseUri) {
        if (contractInfo.totalSupply === contractInfo.maxSupply) {
          setMessage(`All tokens were already minted!`)
          setLoading(false)
          return
        }
        setMessage(`${contractInfo?.maxSupply} tokens found, fetching metadata...`)
        fetchTokensMetadata()
      }
    }
    fetchAllMetadata()
  }, [contractInfo])

  async function fetchTokensMetadata() {
    if (contractInfo?.baseUri) {
      const metadataArray = []
      try {
        for (let i = contractInfo?.totalSupply + 1; i <= contractInfo.maxSupply; i++) {
          setMessage(`Fetching token #${i}`)
          const metadata = await fetchMetadata(contractInfo.baseUri, i)
          metadataArray.push(metadata)
        }
      } catch {
        setMessage(`Failed to fetch metadata for tokens, please try again later`)
        setLoading(false)
        return
      }
      setMessage(`${contractInfo?.maxSupply} tokens found, metadata fetched.`)
      aggregateAttributes(metadataArray)
    }
  }

  async function aggregateAttributes(metadataArray: any[]) {
    const aggregated: Aggregations = {}
    metadataArray.forEach((metadata) => {
      metadata.attributes.forEach((attribute: any) => {
        if (!aggregated[attribute.trait_type]) {
          aggregated[attribute.trait_type] = {
            values: {},
            totalCount: 0,
          }
        }
        if (!aggregated[attribute.trait_type].values[attribute.value]) {
          aggregated[attribute.trait_type].values[attribute.value] = {
            count: 0,
            percentage: 0,
          }
        }
        aggregated[attribute.trait_type].values[attribute.value].count += 1
        aggregated[attribute.trait_type].totalCount += 1
      })
    })
    Object.keys(aggregated).forEach((key) => {
      const values = aggregated[key].values
      const totalCount = aggregated[key].totalCount
      Object.keys(values).forEach((value) => {
        values[value].percentage = values[value].count / totalCount
      })
    })
    setAggregated(aggregated)
    calculateScores(metadataArray, aggregated)
  }

  async function calculateScores(metadataArray: any[], aggregatedAttributes: Aggregations) {
    const scores: number[] = []
    metadataArray.forEach((metadata) => {
      const score = metadata.attributes.reduce((acc: number, attribute: any) => {
        return acc + aggregatedAttributes[attribute.trait_type].values[attribute.value].percentage
      }, 0)
      scores.push(score)
    })

    const minScore = Math.min(...scores)
    const found = metadataArray[scores.indexOf(minScore)]
    if (found) {
      setRarest(found)
      setMessage(`Rarest token found, tracking mint events...`)
    } else {
      setMessage(`😓 No rarest token found, refresh to rescan`)
      setLoading(false)
    }
  }

  return {
    message,
    loading,
    rarest,
    aggregated,
  }
}

async function fetchMetadata(baseUri: string, index: number) {
  return fetch(`${baseUri}/${index}.json`)
    .then(res => res.json())
    .then(json => json)
}