interface BlockchainNetwork {
  name: string
  id: number
  chainId: string
  rpcUrls: string[]
  symbol: string
}

export const mainNetwork: BlockchainNetwork = {
  name: 'Polygon Mainnet',
  id: 137,
  chainId: '0x89',
  rpcUrls: ['https://polygon-rpc.com'],
  symbol: 'MATIC',
}

export const testnetNetwork: BlockchainNetwork = {
  name: 'Mumbai Testnet',
  id: 80001,
  chainId: '0x13881',
  rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
  symbol: 'MATIC',
}

export const network: BlockchainNetwork =
  process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' ? testnetNetwork : mainNetwork

export const isMainNetwork = network.id === mainNetwork.id
