import useMetaMask from "../hooks/useMetaMask"
import { network } from "../util/blockchainNetworks"
import logo from "../logo-dark.svg"
import { Link } from "react-router-dom"

const shortenAddress = (address: string) =>
  `${address.slice(0, 6)}...${address.slice(address.length - 4, address.length)}`

export function Header() {
  const {account} = useMetaMask()
  return (
    <>
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0 mr-10">
                <img className="h-12" src={logo} alt="logo" />
              </div>
              <Link to='/contract'
                className="hover:bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium flex items-center gap-x-2" 
                aria-current="page"
              >
                New Scan
              </Link>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6 text-white gap-x-3">
                <div
                  className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium flex items-center gap-x-2" 
                  aria-current="page"
                >
                  <div className="w-2 h-2 bg-green-300 rounded-full"></div>
                  {network.name}
                </div>
                {account ? <p>{shortenAddress(account)}</p> : <></>}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}