import { Route, Routes } from "react-router-dom";
import WalletProvider from "../hooks/useWalletContext";
import { ContractAddressForm } from "./ContractAddressForm";
import { ContractScan } from "./ContractScan";
import { Header } from './Header'

export function AppHome() {
  return (
    <>
      <Header />
      <WalletProvider>
        <Routes>
          <Route index element={<ContractAddressForm />} />
          <Route path=":contractAddress" element={<ContractScan />} />
        </Routes>
      </WalletProvider>
    </>
  )
}