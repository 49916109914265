import React, { createContext, ReactNode, useContext } from "react";
import Web3 from "web3";
import { network } from "../util/blockchainNetworks";
import useMetaMask from "./useMetaMask";

interface WalletContextData {
  web3?: Web3;
  account?: string;
  balance?: string;
  refetchBalance?: () => void;
}

const WalletContext = createContext<WalletContextData>({});

interface WalletProviderProps {
  children?: ReactNode | undefined;
}

const WalletProvider = ({ children }: WalletProviderProps) => {
  const {
    account,
    balance,
    chainId,
    addNetwork,
    connect,
    web3,
    refetchBalance,
  } = useMetaMask();

  let context: WalletContextData = {};
  let Content;

  if (!account) {
    Content = (
      <div className="text-black">
        <div>Ops! It seems you may not be connected to MetaMask</div>
        <button className="max-w-xs" onClick={() => connect()}>
          Connect to MetaMask Wallet
        </button>
      </div>
    )
  } else if (chainId !== network.id) {
    Content = (
      <div className="text-black">
        <div>Ops! It seems you may be connected to another network</div>
        <button onClick={() => addNetwork()}>Connect to {network.name}</button>
      </div>
    )
    context = {
      account,
      balance,
      web3,
      refetchBalance,
    }
  }

  if (Content) {
    return (
      <div className="fixed top-0 left-0 h-full w-full flex flex-col items-center gap-4 justify-center z-50 bg-gray-30 bg-opacity-95 text-center text-white font-bold">
        {Content}
      </div>
    )
  }

  return (
    <WalletContext.Provider value={context}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletContext = () => useContext(WalletContext);

export default WalletProvider;
