import { useParams } from "react-router-dom";
import { useContractScan } from "../hooks/useContractScan";
import Table from "./Table";

export function ContractScan() {
  const { contractAddress } = useParams()

  const { message, loading, rarest, aggregated } = useContractScan(contractAddress!)
  
  const traits = rarest?.attributes.map(({ value, trait_type }: any) => {
    const percentage = aggregated?.[trait_type].values[value].percentage || 0
    return { trait_type, value, percentage: `${(percentage * 100).toFixed()}%` }
  })

  return (
    <div className="w-full">
      <div className="flex flex-col mt-10 gap-y-4">
        <div className="flex items-center justify-center gap-2">
          {loading && <div className="w-4 h-4 bg-green-400 rounded-full animate-pulse"></div>}
          {message}
        </div>
        {rarest && (
          <div className="p-4 border- bor">
            <article className="mx-auto prose">
              <h1 className="text-center">Rarest token #{rarest.edition}</h1>
              <div className="flex items-center justify-center">
                <img src={rarest.image.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/')} className="w-48" />
              </div>
              <div className="text-center">
                <Table people={traits} />
              </div>
              <pre>{JSON.stringify(rarest, null, 2)}</pre>
            </article>
          </div>
        )}
      </div>
    </div>
  )
}