import Web3 from 'web3'
import nft721abi from './NFT721.json'

// const web3 = new Web3('https://polygon-mainnet.g.alchemy.com/v2/B7-n_D7E2QDKBBS8Eza_G-VKQ2fhqpd0')
const web3 = new Web3('https://polygon-mumbai.g.alchemy.com/v2/ftBlpqsKjbbvPeRkMWS8sCeVQIpBbSbb')

const gas = 1200000
const fallbackGasPrice = '300000000000'

export const getBaseUri = async (contractAddress: string): Promise<string> => {
  const nft721 = new web3.eth.Contract(nft721abi as any, contractAddress)
  const tokenUri = await nft721.methods.tokenURI(1).call()
  const ipfsLink = tokenUri.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/')
  const lastIndex = ipfsLink.lastIndexOf('/')
  const baseUri = ipfsLink.substring(0, lastIndex)
  return baseUri
}

export const getMaxSupply = async (contractAddress: string): Promise<string> => {
  const nft721 = new web3.eth.Contract(nft721abi as any, contractAddress)
  const maxSupply = await nft721.methods.maxSupply().call()
  return maxSupply
}

export const mint = async (from: string, contractAddress: string, metamask: any, onReceipt: (receipt: any) => void) => {
  if (!metamask) return
  const nft721 = new metamask.eth.Contract(nft721abi as any, contractAddress)
  const gasPrice = (await web3.eth.getGasPrice()) ?? fallbackGasPrice
  const value = await nft721.methods.cost().call()
  await nft721.methods.mint(1).send({ from, gas, gasPrice, value }).on('receipt', onReceipt)
}

export const getTotalSupply = async (contractAddress: string): Promise<string> => {
  const nft721 = new web3.eth.Contract(nft721abi as any, contractAddress)
  const totalSupply = await nft721.methods.totalSupply().call()
  return totalSupply
}