import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function ContractAddressForm() {
  const [contractAddress, setContractAddress] = useState('0x57D7669256C2cA7B3D0B50701977F5Df03459b98')
  const navigate = useNavigate()

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    navigate(`/contract/${contractAddress}`);
  }

  return (
    <div>
      <div className="max-w-md mx-auto mt-20">
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" value="true"/>
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="contract-address" className="sr-only">Contract address</label>
              <input
                id="contract-address"
                name="contract"
                type="text"
                required
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Insert the contract address to be scanned"
                onChange={(e) => setContractAddress(e.target.value)}
                value={contractAddress}
              />
            </div>
          </div>

          <div>
            <button type="submit" className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={handleClick}>
              Scan
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}