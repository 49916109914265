import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { AppHome } from './components/AppHome';
import Home from './components/Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contract/*" element={<AppHome />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
